export const getColorCode = (bu) => {
    switch (bu) {
      case 'BU_TECH':
        return 'var(--peach-tech)';
      case 'BU_CONSEIL':
        return 'var(--green-conseil)';
      case 'BU_ENGINEERING':
        return 'var(--blue-engineering)';
      default:
        return 'gray';
    }
  }